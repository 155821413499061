import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Aleena Khan | Software Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Passionate about learning and problem solving.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hey there! My name is',
  name: 'Aleena Khan',
  subtitle: 'I am a Full-Stack Software Engineer.',
  desc: 'Experienced with Ruby on Rails, React.',
  cta: 'Reach Out',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: 'Hello! My name is Aleena Khan. I’m a software engineer based in NYC, currently working at Teladoc Health as a software engineer. I graduated in 2020 from Queens College with a Bachelor\'s Degree in Computer Science.',
  paragraphTwo: 'Languages and tools I\'m proficient in:',
  paragraphThree: ' When I\'m not working, you can catch me exploring NYC parks, playing the ukulele, and reading a good sci-fi series.  ',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: '',
    title: 'Movie Review Classifier',
    info: 'A classifier that performs sentiment analysis of movie reviews as either negative/postive using a from-scratch Naive Bayes model (using bag-of-word features with add-one smoothing.)',
    info2: 'PYTHON, NLP',
    url: 'https://github.com/arhumkhan/movie-review-classifier',
    repo: '',
  },
  {
    id: nanoid(),
    img: '',
    title: 'Bézout Calculator',
    info: 'A program that expresses the GCD of two numbers as a linear combination (utilizing Bézout\'s lemma) using the Euclidean Algorithm.',
    info2: 'JAVA',
    url: 'https://github.com/arhumkhan/bezout-calculator',
    repo: '', 
  },
  // {
  //   id: nanoid(),
  //   img: '',
  //   title: 'Meal Planner',
  //   info: 'An app that generates a weekly set of meals along with their recipes, and compiles a list of groceries to buy each week based on the ingredients of all of the week\'s meals.',
  //   info2: 'REACT, JAVASCRIPT, NODEJS',
  //   url: 'https://github.com/arhumkhan/movie-review-classifier',
  //   repo: '', 
  // },
];

// CONTACT DATA
export const contactData = {
  cta: 'Feel free to reach out, on LinkedIn or via email, about any opportunities, questions, and/or to learn more!',
  btn: 'Get In Touch',
  email: 'khan.arhum.a@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/arhumkhan/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/arhumkhan',
    },
    {
      id: nanoid(),
      name: 'envelope-square',
      url: 'mailto:khan.arhum.a@gmail.com',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
